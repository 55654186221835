<template>
  <v-dialog
      v-model="dialog"
      width="800px">
    <v-card>
      <v-card-title>
        <span>SchoolPay detail <span class="badge badge-info" v-if="school_pay.score_name"> {{school_pay.score_name}}</span></span>
        <hr>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <div class="d-flex justify-space-between">
                <h3>School contact</h3>
                <v-btn
                  @click="toggleEdit"
                  class="btn btn-primary text-white"
                > 
                  {{ !editMode ? 'Edit' : 'Cancel' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>School name: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.school_name}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.school_name"
                outlined
                dense
                :error="$v.school_pay.school_name.$error"
              >
                <template v-slot:label>
                  School name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.school_name.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Email: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.email}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.email"
                outlined
                :error="$v.school_pay.email.$error"
                dense
              >
                <template v-slot:label>
                  Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.email.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="school_pay.website">
            <v-col cols="12" md="6">
              <b>Website: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
             <a :href="school_pay.website" target="_blank">{{school_pay.website}}</a>
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.website"
                outlined
                :error="$v.school_pay.website.$error"
                dense
              >
                <template v-slot:label>
                  Website <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.website.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="!editMode">
            <v-col cols="12" md="6">
              <b>Full school address: </b>
            </v-col>
            <v-col cols="12" md="6">
              {{school_pay.address_line_1}} {{school_pay.address_line_2}}
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="editMode">
            <v-col cols="12" md="6">
              <b>Address line 1: </b>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="school_pay.address_line_1"
                outlined
                dense
                :error="$v.school_pay.address_line_1.$error"
              >
                <template v-slot:label>
                  Address line 1 <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.address_line_1.$error">This information is required</span>
            </v-col>
          </v-row>
          
          <v-row class="mt-2" v-if="editMode">
            <v-col cols="12" md="6">
              <b>Address line 2: </b>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="school_pay.address_line_2"
                outlined
                dense
                label="Address line 2"
                :error="$v.school_pay.address_line_2.$error"
              ></v-text-field>
              <span class="text-danger" v-if="$v.school_pay.address_line_2.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Suburb/town: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.suburb}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.suburb"
                outlined
                dense
                :error="$v.school_pay.suburb.$error"
              >
                <template v-slot:label>
                  Suburb <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.suburb.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Post code: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.post_code}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.post_code"
                outlined
                dense
                :error="$v.school_pay.post_code.$error"
              >
              <template v-slot:label>
                  Post code <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.post_code.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Phone: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.phone}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.phone"
                outlined
                dense
                :error="$v.school_pay.phone.$error"
              >
                <template v-slot:label>
                  Phone <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.phone.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Other phone: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.alt_phone}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.alt_phone"
                outlined
                dense
                :error="$v.school_pay.alt_phone.$error"
              >
                <template v-slot:label>
                  Other phone <span class="text-danger"></span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.alt_phone.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <h3>Finance contact person</h3>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person full name: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.contact_person_full_name}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.contact_person_full_name"
                outlined
                dense
                :error="$v.school_pay.contact_person_full_name.$error"
              >
                <template v-slot:label>
                  Contact person full name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.contact_person_full_name.$error">This information is required</span>

            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person email: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.contact_person_email}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.contact_person_email"
                outlined
                dense
                :error="$v.school_pay.contact_person_email.$error"
              >
                <template v-slot:label>
                  Contact person email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.contact_person_email.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Contact person phone: </b>
            </v-col>
            <v-col cols="12" md="6" v-if="!editMode">
              {{school_pay.contact_person_phone}}
            </v-col>
            <v-col cols="12" md="6" v-if="editMode">
              <v-text-field
                v-model="school_pay.contact_person_phone"
                outlined
                dense
                :error="$v.school_pay.contact_person_phone.$error"
              >
                <template v-slot:label>
                  Contact person phone <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.school_pay.contact_person_phone.$error">This information is required</span>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <b>Remarks: </b>
            </v-col>

            <v-col cols="12" md="6" v-if="!editMode">
              <p v-html="school_pay.score_approval_remarks"></p>
            </v-col>

            <v-col cols="12" md="12" v-if="editMode">
              <ckeditor :config="editorConfig"
                        v-model="school_pay.score_approval_remarks" >
              </ckeditor>
              <span class="text-danger" v-if="$v.school_pay.score_approval_remarks.$error">This information is required</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn  
          color="btn btn-primary mr-2" 
          dark 
          x-large 
          @click="update()" 
          :loading="loading"
        >
          Save
        </v-btn>
        <v-btn
            x-large
            class="cancel-btn"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import SchoolPayApplicationService from "@/services/school-pay/SchoolPayApplicationService";
import {required,email} from "vuelidate/lib/validators";

const schoolPayApplication = new SchoolPayApplicationService();
export default {
  name: "SchoolPayApplicationDetail",
  validations:{
    school_pay: {
      score_approval_remarks: {},
      school_name: {required},
      email: {required,email},
      website: {},
      address_line_1: {required},
      address_line_2: {},
      suburb: {required},
      post_code: {required},
      phone: {required},
      alt_phone: {},
      contact_person_full_name: {required},
      contact_person_email: {required,email},
      contact_person_phone: {required}
    },
  },
  data() {
    return {
      dialog: false,
      school_pay: {
        score_approval_remarks: '',
        school_name: '',
        email: '',
        website: '',
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        post_code: null,
        phone: '',
        alt_phone: '',
        contact_person_full_name: '',
        contact_person_email: '',
        contact_person_phone: ''
      },
      editMode: false,
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      loading: false,
    }
  },
  methods: {
    openDialog(userId) {
      this.editMode = false;

      this.dialog = true;
      schoolPayApplication
      .showByUserId(userId)
      .then(response => {
        this.school_pay = response.data.schoolPay;
        this.isLoading = false;
      })
      .catch((err) => {
        this.$snotify.error('Oops looks like something went wrong');
        this.searchFieldDialog=false;
        this.isLoading = false;
      });
    },

    toggleEdit(){
      this.editMode = !this.editMode;
    },

    update(){
      this.$v.school_pay.$touch()
      if (this.$v.school_pay.$error) {
        setTimeout(() => {
          this.$v.school_pay.$reset()
        }, 3000);
      } else {
        this.loading = true;

        schoolPayApplication
        .update(this.school_pay.id, this.school_pay)
        .then(response => {
          this.$snotify.success("School pay updated");
          this.loading = false;
          this.resetForm();
          this.closeDialog();
          this.$emit('refresh');
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.errors;
        })
      } 
    },

    resetForm(){
      this.school_pay = {
        score_approval_remarks: '',
        school_name: '',
        email: '',
        website: '',
        address_line_1: '',
        address_line_2: '',
        suburb: '',
        post_code: null,
        phone: '',
        alt_phone: '',
        contact_person_full_name: '',
        contact_person_email: '',
        contact_person_phone: ''
      };
      this.editMode = false;
    },

    closeDialog(){
      this.dialog = false;
    }
  }
}
</script>