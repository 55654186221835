<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Online marker information
          </h3>
        </div>
        <div class="card-toolbar" v-if="edit">
          <v-btn class="mx-2" x-large text  @click="close()">
            Cancel
          </v-btn>
          <v-btn :disabled="buttonDisabled" x-large dark  @click="updateProfile()">
            Save
          </v-btn>
        </div>
        <div class="card-toolbar" v-if="!edit">
          <v-btn
              text
              x-large
              @click="loginAsOnlineMarker(online_marker_profile.id)"
              class="ml-2 mr-1 cancel-btn"
          >
            Log in as examiner
          </v-btn>

          <v-btn x-large dark  @click="editProfile()">
            Edit
          </v-btn>
        </div>
      </div>

      <form class="form">
        <div class="card-body" v-if="edit">
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Title  <span class="text-danger">*</span>:</label>
            <div class="col-lg-8 col-xl-6">
              <p class="is-invalid text-danger text-sm-body-1" v-if="errors.title">* {{errors.title[0]}}</p>
              <v-select
                  label="Title" :error="$v.online_marker_profile.title.$error"
                  v-model="online_marker_profile.title"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="titles"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="$v.online_marker_profile.title.$error">This information is required</span>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">First name  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field outlined dense :error="$v.online_marker_profile.first_name.$error"
                            v-model="online_marker_profile.first_name"
              ></v-text-field>
              <span class="text-danger" v-if="$v.online_marker_profile.first_name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.first_name">* {{errors.first_name[0]}}<br></span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Middle name</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field outlined dense
                            v-model="online_marker_profile.middle_name"
              ></v-text-field>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Surname  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined :error="$v.online_marker_profile.last_name.$error"
                  dense
                  v-model="online_marker_profile.last_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.last_name">* {{errors.last_name[0]}}<br></span>
              <span class="text-danger" v-if="$v.online_marker_profile.last_name.$error">This information is required</span>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Role  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-radio-group v-model="online_marker_profile.role" row>
                <v-radio
                    label="Teacher" :error="$v.online_marker_profile.role.$error"
                    value="teacher"
                    color="indigo"
                ></v-radio>
                <v-radio
                    label="Parent"
                    value="parent"
                    color="red"
                ></v-radio>
                <v-radio
                    color="indigo darken-3"
                    label="Adult learner"
                    value="adult_learner"
                ></v-radio>
              </v-radio-group>
              <span class="text-danger" v-if="errors.role">* {{errors.role[0]}}<br></span>
              <span class="text-danger" v-if="$v.online_marker_profile.role.$error">This information is required</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Email address  <span class="text-danger">*</span></label
            >
            <div class="col-lg-8 col-xl-6">
              <v-text-field @blur="checkDuplicateEmail" outlined dense :error="$v.online_marker_profile.email.$error"
                            v-model="online_marker_profile.email"
              ></v-text-field>
              <span class="text-danger" v-if="$v.online_marker_profile.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.online_marker_profile.email.email">This information is not valid</span>
              <span class="font-weight-medium text-danger" v-if="messages">{{messages}}</span>
              <span class="text-danger" v-if="errors.email" >{{errors.email[0]}}</span>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right"> Phone  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined :error="$v.online_marker_profile.phone.$error"
                  dense
                  v-model="online_marker_profile.phone"
              ></v-text-field>
              <span class="text-danger" v-if="errors.phone">* {{errors.phone[0]}}<br></span>
              <span class="text-danger" v-if="$v.online_marker_profile.phone.$error">This information is required<br></span>
              <span class="text-danger" v-if="!$v.online_marker_profile.phone.containTenDigit || !$v.online_marker_profile.phone.numeric">Phone must contain 10 digits or must be number</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Mobile</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  v-model="online_marker_profile.mobile"
              ></v-text-field>
              <span class="text-danger" v-if="!$v.online_marker_profile.mobile.containTenDigit || !$v.online_marker_profile.mobile.numeric">Mobile must contain 10 digits or must be number</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Work phone</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  v-model="online_marker_profile.work_phone"
              ></v-text-field>
              <span class="text-danger" v-if="!$v.online_marker_profile.work_phone.containTenDigit || !$v.online_marker_profile.work_phone.numeric">Work Phone must contain 10 digits or must be number</span>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="!edit">
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-8 col-xl-6">
              <h5 class="font-weight-bold mb-6">Online marker Info</h5>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Full name</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.full_name">{{online_marker_profile.full_name}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Role</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.role_text">{{online_marker_profile.role_text}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Email address</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.email">{{online_marker_profile.email}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Phone</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.phone_format">{{online_marker_profile.phone_format ? online_marker_profile.phone_format : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Mobile Phone</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.mobile_format">{{online_marker_profile.mobile_format ? online_marker_profile.mobile_format:'NA'}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-4 col-lg-4 col-form-label text-right">Work phone</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="online_marker_profile.work_phone_format">{{online_marker_profile.work_phone_format ? online_marker_profile.work_phone_format : 'NA' }}</span>
            </div>
          </div>
        </div>
      </form>

    </div>
  </v-app>
</template>
<script>
import {email, numeric, required} from "vuelidate/lib/validators";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
const accountholder=new AccountholderService();
const onlineMarker=new OnlineMarkerService();

export default {
  validations:{
    online_marker_profile:{
      title:{},
      first_name:{required},
      last_name: {required},
      role: {required},
      email: {required,email},
      phone: {
        numeric,
        required,
        containTenDigit: function (value) {
          if(value) {
            return value.length === 10;
          }
          return true;
        }
      },
      mobile: {
        numeric,
        containTenDigit: function (value) {
          if(value) {
            return value.length === 10;
          }
          return true;
        }
      },
      work_phone: {
        numeric,
        containTenDigit: function (value) {
          if(value) {
            return value.length === 10;
          }
          return true;
        }
      },
    }
  },
  props: ['online_marker_profile'],
  name: "OnlineMarkerProfile",
  data() {
    return{
      edit: false,
      errors:[],
      titles:[
        {name: 'None', value: ''},
        {name: 'Ms', value: 'Ms'},
        {name: 'Miss', value: 'Miss'},
        {name: 'Mrs', value: 'Mrs'},
        {name: 'Mr', value: 'Mr'},
        {name: 'Mx', value: 'Mx'},
        {name: 'Dr', value: 'Dr'},
        {name: 'Prof', value: 'Prof'},
      ],
      duplciateEmailStatus:'',
      messages:'',
      buttonDisabled:false,
    }
  },
  methods:{
    loginAsOnlineMarker(onlineMarkerId)
    {
      onlineMarker.loginAsOnlineMarker(onlineMarkerId).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    editProfile(){
      this.edit=true;
    },
    close(){
      this.edit=false;
    },
    submit(){
      this.edit=false;
    },
    updateProfile() {
      this.online_marker_profile['form_type'] = "update-accountholder-profile";
    this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        onlineMarker
            .update(this.online_marker_profile.id, this.online_marker_profile)
            .then(response => {
              this.$emit("update-online-marker");
              this.close();
              this.$snotify.success("Profile information updated");
            })
            .catch((err) => {
              this.errors = err.errors;
            });
      }
    },
    checkDuplicateEmail(){
      accountholder
          .checkDuplicateEmailForUpdate(this.online_marker_profile.id,this.online_marker_profile)
          .then(response => {
            this.messages=response.data.message;
            this.duplciateEmailStatus=response.data.status;
            if(this.duplciateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
            }
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
  }

}
</script>