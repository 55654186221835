<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card card-custom">
            <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                   Notes
                </h3>
              </div>
              <div class="card-toolbar">
                <v-btn x-large dark @click="addNote()">
                 Add note
                </v-btn>
              </div>
            </div>
          </div>
          <template>

          </template>
          <div class="card-body">
            <v-row justify="center" v-if="notes.length > 0">
              <v-col>
                <v-expansion-panels inset>
                  <v-expansion-panel
                      v-for="(note, index) in notes" :key="index"
                  >
                    <v-expansion-panel-header style="background: #e3e3e3;">{{ note.name }} - {{ note.created_at }} <strong class="ml-4 badge badge-primary" style="max-width: 100px" v-if="note.priority != 'urgent'">{{note.priority}}</strong>
                      <strong class="ml-4 badge badge-danger w-48" style="max-width: 100px" v-else>{{note.priority}}</strong></v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12" md="12">
                        <i class="fa fa-edit cursor-pointer mt-3 float-right" @click="editNote(note)" color="red darken-2"></i>
                        <i class="fa fa-check cursor-pointer mt-3 mx-4 float-right" @click="noteMarked(note)" color="blue darken-2"  v-if="note.requires_follow_up"></i>
                        <i class="fa fa-plus cursor-pointer mt-3 float-right" @click="addNoteFollowup(note.id)" color="blue darken-2"  v-if="note.requires_follow_up"></i>
                        <i class="fa fa-trash-alt cursor-pointer mt-3 mx-4 float-right" @click="deleteNote(note.id)" color="blue darken-2" ></i>
                      </v-col>
                      <span v-html="note.description" class="text-justify"></span>
                      <v-row>
                        <v-col cols="4" md="4">
                          <span><b>Follow up required: </b> <span class="badge badge-primary">{{note.requires_follow_up ? 'Yes' : 'No'}}</span></span>
                        </v-col>
                        <v-col cols="4" md="4">
                          <span><b>Follow up date: </b> {{note.formatted_followup_required_date ? note.formatted_followup_required_date : 'NA'}}</span>
                        </v-col>
                        <v-col cols="4" md="4">
                          <span> <b>Added by: </b> {{note.addedBy ? note.addedBy : 'NA'}}</span>
                        </v-col>
                      </v-row>
                      <v-row cols="12" v-if="note.note_attachments && note.note_attachments.length > 0">
                        <v-col cols="12">
                          <b>Attachments</b>
                        </v-col>
                        <v-col cols="12">
                          <span class="mx-1 d-flex align-items-center" v-for="(attachment, index) in note.note_attachments" :key="index">
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'image'" :href="attachment.file_path.original" target="_blank"><v-img class="my-2" :src="attachment.file_path.original" max-width="100px" max-height="100px"></v-img></a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'doc'" :href="attachment.file_path.original" target="_blank"><i class="mx-1 fa-solid fa-file-word"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'cvs'" :href="attachment.file_path.original" target="_blank"><i class="mx-1 fa-solid fa-file-cvs"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'pdf'" :href="attachment.file_path.original" target="_blank"><i class="mx-1 fa-solid fa-file-pdf"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.file_path && attachment.file_type== 'xls'" :href="attachment.file_path.original" target="_blank"><i class="mx-1 fa-solid fa-file-excel"></i>{{attachment.name}}</a>
                            <a v-if="attachment && attachment.url" :href="attachment.url" target="_blank"><i class="mx-1 fa-solid fa-link" small></i>{{attachment.name}} Link</a>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row v-if="note.note_followups && note.note_followups.length > 0">
                        <v-col cols="12" class="text-left">
                          <template>
                            <v-timeline v-for="(followup, index) in note.note_followups" :key="index">
                              <v-timeline-item >
                                {{ followup.formatted_date }}
                                <i class="mx-2 cursor-pointer fas fa-trash" @click="deleteFollowup(followup.id)"></i><br/>
                                <b>Followed by: </b> <span class="ml-1 font-italic">{{ followup.addedBy ? followup.addedBy : 'N/A' }}<br/>
                              <p class="text-justify" v-html="followup.remarks"></p>
                              </span>
                              </v-timeline-item>
                            </v-timeline>
                          </template>
                        </v-col>
                      </v-row>

                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <div v-else class="text-center card-body">
              <NotFound
                  :message="'Notes not available'"
              ></NotFound>
            </div>
          </div>

          <create-and-update ref="add-notes" @refresh="getNotes"></create-and-update>
          <AddNoteFollowup ref="add-note-followup" @refresh="getNotes"></AddNoteFollowup>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import NoteService from "@/services/note/NoteService";
import AddNoteFollowup from "./AddNoteFollowup";
import NoteFollowupService from "@/services/note-followup/NoteFollowupService";
import NotFound from "@/view/pages/view/components/NotFound";
import CreateAndUpdate from "./CreateAndUpdate";

const note = new NoteService();
const noteFollowup = new NoteFollowupService();
export default {
  name: "Notes",
  components: {CreateAndUpdate, NotFound, AddNoteFollowup},
  data() {
    return {
      notes: [],
      userId: ''
    }
  },
  mounted() {
    if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
      this.userId = this.$route.params.accountholderId;
    } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
      this.userId = this.$route.params.examinerId;
    } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
      this.userId = this.$route.params.supervisorId;
    } else {
      this.userId = this.$route.params.onlineMarkerId;
    }
    this.getNotes();
  },
  methods: {

    addNote() {
      this.$refs['add-notes'].addNotes(this.userId);
    },

    editNote(note) {
      this.$refs['add-notes'].editNote(note);
    },

    addNoteFollowup(id) {
      this.$refs['add-note-followup'].addNoteFollowup(id);
    },

    getNotes() {
      note.getAllUserNote(this.userId).then(response => {
        this.notes = response.data.data;
      });
    },

    noteMarked(item) {
      note.noteMarked(item.id, item).then((response) => {
        this.$snotify.success("Note updated");
        this.getNotes();
      })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    deleteNote(id) {
      this.$confirm({
        message: `Are you sure you want to delete this note? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            note
                .delete(id)
                .then((response) => {
                  this.getNotes();
                  this.$snotify.success("Note deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    deleteFollowup(id) {
      this.$confirm({
        message: `Are you sure to delete follow up note? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            noteFollowup
                .delete(id)
                .then((response) => {
                  this.getNotes();
                  this.$snotify.success("Follow up note deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>
<style scoped>

.text-color {
  color: #646464;
}
.v-item-group {
  position: unset;
}
</style>