<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Security
        </h3>
      </div>
      <div class="card-toolbar" v-if="edit">
        <v-btn x-large @click="cancelEdit" class="cancel-btn mr-1" text >Cancel </v-btn>
        <v-btn  dark x-large
                @click="updateSecurity()"
        >
          Save
        </v-btn>
      </div>
      <div class="card-toolbar" v-if="!edit">
        <v-btn  dark x-large
                @click="editSecurity()"
        >
          Edit
        </v-btn>
      </div>
    </div>
    <form v-if="edit" class="form">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"> Password</label>
          <div class="col-lg-8 col-xl-6">
            <v-btn depressed  color="btn btn-primary" dark medium
                   @click="openResetPassword">Reset password
            </v-btn>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Two factor authentication</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <label class="radio mr-2 ml-1">
                <input
                  type="radio" 
                  v-model="user_security.is_mfa_enabled" 
                  value="1"
                  :disabled="user_security.is_mfa_enabled"
                />
                <span></span>
                <b-button id="tooltip-target-1" style="background: transparent; border: 0; font-weight: 600">
                  Enabled
                </b-button>
                <b-tooltip target="tooltip-target-1" triggers="hover">
                  Two factor authentication can only be activated by user as it requires a mobile device.
                </b-tooltip>
              </label>
              <label class="radio mr-2 ml-1">
                <input v-model="user_security.is_mfa_enabled" value="0" type="radio"/>
                <span></span>&nbsp;&nbsp;Disabled</label>
            </div>

          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Email authentication</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <label class="radio mr-2 ml-1">
                <input 
                  type="radio" 
                  v-model="user_security.is_email_authentication_enabled" 
                  value="1"
                  :disabled="user_security.is_email_authentication_enabled"
                />
                <span></span>
                <b-button id="tooltip-target-2" style="background: transparent; border: 0; font-weight: 600">
                  Enabled
                </b-button>
                <b-tooltip target="tooltip-target-2" triggers="hover">
                  Can not enable from admin
                </b-tooltip>
              </label>
              <label class="radio mr-2 ml-1">
                <input 
                  v-model="user_security.is_email_authentication_enabled" 
                  value="0" 
                  type="radio"
                />
                <span></span>&nbsp;&nbsp;Disabled</label>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Email verification</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <v-switch
                  v-model="user_security.is_login_verified"
                  :label="user_security.is_login_verified ?'Verified':'Not verified'"
                  hide-details
              ></v-switch>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Enroller status</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <v-switch v-model="user_security.is_active"
                        :label="user_security.is_active ? 'Active':'Inactive'"
                        hide-details></v-switch>
            </div>
          </div>
        </div>

        <div class="form-group row align-items-center" v-if="user_security.is_examiner">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Examiner status</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <v-switch v-model="user_security.is_active_examiner"
                        :label="user_security.is_active_examiner ? 'Active':'Inactive'"
                        hide-details></v-switch>
            </div>
          </div>
        </div>


        <div class="form-group row align-items-center" v-if="user_security.is_supervisor">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Supervisor status</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <v-switch v-model="user_security.is_active_supervisor"
                        :label="user_security.is_active_supervisor ? 'Active':'Inactive'"
                        hide-details></v-switch>
            </div>
          </div>
        </div>

        <div class="form-group row align-items-center" v-if="user_security.is_online_marker">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Marker status</label>
          <div class="col-lg-8 col-xl-6">
            <div class="checkbox-inline">
              <v-switch v-model="user_security.is_active_online_marker"
                        :label="user_security.is_active_online_marker ? 'Active':'Inactive'"
                        hide-details></v-switch>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="card-body align-items-center" v-if="!edit">
      <div class="form-group row">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Two Factor Authentication:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_mfa_enabled ? 'Enabled' : 'Disabled' }}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Email Authentication:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_email_authentication_enabled ? 'Enabled' : 'Disabled' }}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Email Verification:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_login_verified ? 'Verified' : 'Not Verified' }}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Enroller Status:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_active ? 'Active' : 'Inactive' }}
        </div>
      </div>

      <div class="form-group row" v-if="user_security.is_examiner">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Examiner Status:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_active_examiner ? 'Active' : 'Inactive' }}
        </div>
      </div>


      <div class="form-group row" v-if="user_security.is_supervisor">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Supervisor Status:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_active_supervisor ? 'Active' : 'Inactive' }}
        </div>
      </div>

      <div class="form-group row" v-if="user_security.is_online_marker">
        <label class="col-xl-5 col-lg-5 col-form-label text-right">Marker Status:</label>
        <div class="col-lg-7 col-xl-7">
          {{ user_security.is_active_online_marker ? 'Active' : 'Inactive' }}
        </div>
      </div>

    </div>
    <v-dialog v-model="resetPasswordDialog" max-width="500">
      <v-card>
        <v-card-title>
          Reset password
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" sm="6" md="12">
                <v-switch
                    v-model="reset.auto_generate"
                    :label="`Auto generate password`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-if="!reset.auto_generate">
                <v-text-field
                    v-model="reset.new_password"
                    dense outlined  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                >
                  <template v-slot:label>
                    Enter new password <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger"  v-if="$v.reset.new_password.$error">This information is required <br></span>
                <span class="font-italic ml-5" v-if="!$v.reset.new_password.minLength">Password must have at least 6 letters. <br></span>
                <span class="font-italic ml-5" v-if="!$v.reset.new_password.containsUppercase">Password must contain uppercase letters. <br></span>
                <span class="font-italic ml-5" v-if="!$v.reset.new_password.containLowercase">Password must contain lowercase letters.<br></span>
                <span class="font-italic ml-5" v-if="!$v.reset.new_password.containsNumber">Password must contain at least one number. <br></span>
                <span class="font-italic ml-5" v-if="!$v.reset.new_password.containsSymbol">Password must contain symbols.</span>
              </v-col>
              <v-col cols="12" sm="6" md="12" v-if="!reset.auto_generate">
                <v-text-field outlined dense :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showConfirmPassword ? 'text' : 'password'"
                              v-model="reset.password_confirmation" @click:append="showConfirmPassword = !showConfirmPassword"
                >
                  <template v-slot:label>
                    Enter Confirm password <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="show && !$v.reset.password_confirmation.sameAsPassword">Passwords must be identical</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                x-large
                text
                class="cancel-btn"
                @click="closeResetPasswordDialog">
              Cancel
            </v-btn>
            <v-btn
               x-large
                dark :loading="resetButtonLoading"
               @click="resetPassword">
              Reset
            </v-btn>

          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="security_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>

</template>
<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import {minLength, required, sameAs } from "vuelidate/lib/validators";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";

const accountholder = new AccountholderService();
const examiner = new ExaminerService();
const supervisor = new SupervisorService();
const onlineMarker = new OnlineMarkerService();

export default {
  name: "Security",
  props:['user_security'],

  data(){
    return{
      menu: false,
      edit: false,
      errors:[],
      is_email_verify: false,
      resetPasswordDialog: false,
      reset:{
        userId:'',
        auto_generate: true,
        new_password:'',
        password_confirmation:'',
      },
      security:{},
      show: false,
      showPassword: false,
      showConfirmPassword: false,
      resetButtonLoading: false,
      security_overlay:false,
    }
  },
  methods:{
    editSecurity()
    {
      this.edit = true;
    },

    cancelEdit()
    {
      this.edit=false;
    },

    openResetPassword(){
      this.resetPasswordDialog=true;
      this.reset.userId=this.userId;
      this.reset.new_password='';
      this.reset.password_confirmation='';
    },
    closeResetPasswordDialog(){
      this.resetPasswordDialog=false;
      this.reset.auto_generate=true;
      this.reset.new_password='';
      this.reset.password_confirmation='';
      this.$v.$reset();
    },
    updateSecurity(){
      this.security.is_mfa_enabled=this.user_security.is_mfa_enabled;
      this.security.is_active=this.user_security.is_active;
      this.security.is_active_examiner=this.user_security.is_active_examiner;
      this.security.is_active_supervisor=this.user_security.is_active_supervisor;
      this.security.is_active_online_marker=this.user_security.is_active_online_marker;
      this.security.is_email_authentication_enabled=this.user_security.is_email_authentication_enabled;
      this.security.is_login_verified=this.user_security.is_login_verified;
      if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
        this.updateAccountHolderSecurity();
      } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
        this.updateExaminerSecurity();
      } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
        this.updateSupervisorSecurity();
      } else {
        this.updateOnlineMarkerSecurity();
      }
    },
    resetPassword(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        if(!this.$v.reset.password_confirmation.sameAsPassword) {
          this.show = true;
        }
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.$confirm({
          message: `Are you sure you want to reset password for this user? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              this.resetButtonLoading=true;
              if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
                this.resetAccountHolderPassword();
              } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
                this.resetExaminerPassword();
              } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
                this.resetSupervisorPassword();
              } else {
                this.resetOnlineMarkerPassword();
              }
            }
          }
        })
      }
    },
    updateAccountHolderSecurity(){
      this.security_overlay=true;
      accountholder
          .update(this.user_security.id, this.security)
          .then(response => {
            this.$snotify.success("Updated Successfully");
            this.$emit("update");
            this.$v.$reset();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.security_overlay = false;
      });

    },
    updateExaminerSecurity(){
      this.security_overlay=true;
      examiner
          .update(this.user_security.id, this.security)
          .then(response => {
            this.$snotify.success("Updated Successfully");
            this.$emit("update");
            this.$v.$reset();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.security_overlay = false;
      });

    },
    updateSupervisorSecurity(){
      this.security_overlay=true;
      supervisor
          .update(this.user_security.id, this.security)
          .then(response => {
            this.$snotify.success("Updated Successfully");
            this.$emit("update");
            this.$v.$reset();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.security_overlay = false;
      });

    },
    updateOnlineMarkerSecurity(){
      this.security_overlay=true;
      onlineMarker
          .update(this.user_security.id, this.security)
          .then(response => {
            this.$snotify.success("Updated Successfully");
            this.$emit("update");
            this.$v.$reset();
          })
          .catch((err) => {
            this.errors = err.errors;
          })
          .finally(() => {
            this.security_overlay = false;
          });

    },
    resetAccountHolderPassword(){
      accountholder
          .resetPassword(this.userId, this.reset)
          .then((response) => {
            this.closeResetPasswordDialog();
            this.resetButtonLoading=false;
            this.$snotify.success("New password email to user");
          })
          .catch((err) => {
            this.resetButtonLoading=false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetExaminerPassword(){
      examiner
          .resetPassword(this.userId, this.reset)
          .then((response) => {
            this.closeResetPasswordDialog();
            this.resetButtonLoading=false;
            this.$snotify.success("New password email to user");
          })
          .catch((err) => {
            this.resetButtonLoading=false;
            this.$snotify.error("Oops something went wrong");
          });

    },
    resetSupervisorPassword(){
      supervisor
          .resetPassword(this.userId, this.reset)
          .then((response) => {
            this.closeResetPasswordDialog();
            this.resetButtonLoading=false;
            this.$snotify.success("New password email to user");
          })
          .catch((err) => {
            this.resetButtonLoading=false;
            this.$snotify.error("Oops something went wrong");
          });

    },
    resetOnlineMarkerPassword(){
      onlineMarker
          .resetPassword(this.userId, this.reset)
          .then((response) => {
            this.closeResetPasswordDialog();
            this.resetButtonLoading=false;
            this.$snotify.success("New password has been emailed to his/her email address.");
          })
          .catch((err) => {
            this.resetButtonLoading=false;
            this.$snotify.error("Oops something went wrong");
          });
    },
  },
  validations(){
    if (!this.reset.auto_generate) {
      return this.rules;
    }else{
      return false
    }
  },
  computed:{
    rules() {
      return {
        reset: {
          new_password: {
            required,
            minLength: minLength(6),
            containsUppercase: function (value) {
              return /[A-Z]/.test(value)
            },
            containLowercase: function (value) {
              return /[a-z]/.test(value)
            },
            containsNumber: function (value) {
              return /[0-9]/.test(value)
            },
            containsSymbol: function (value) {
              return /[@!#$%^&?]/.test(value)
            }
          },
          password_confirmation: {
            required,
            sameAsPassword: sameAs('new_password')
          }
        }
      }
    },
    userId() {
      if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
        return this.$route.params.accountholderId;
      } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
        return this.$route.params.examinerId;
      } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
        return this.$route.params.supervisorId;
      } else {
        return this.$route.params.onlineMarkerId;
      }
    },

  }
}
</script>