import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class UserSettingService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user-setting';
    }
    deleteDigitalSignatureImg(id) {
        let url = `${this.#api}/signature-img/${id}`;
        return apiService.post(url)
    }

    deleteWWCCImg(id) {
        let url = `${this.#api}/wwcc-img/${id}`;
        return apiService.post(url)
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }
    update(id,data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url,data)
    }
    findByUserId(userId){
        let url = `${this.#api}/user/${userId}`;
        return apiService.get(url)
    }

    getAllWWCCExpiryDate(data={},index = null) {
        let url = `${this.#api}/get/all/expiry-date`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    exportChildSafety(data){
        let url = `${this.#api}/export/csv`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;

        }
        window.open(url, "_blank");
    }

    getByUser(userId) {
        let url = `${this.#api}/get/user/${userId}`;
        return apiService.get(url)
    }

    reminderExpiryDate(id) {
        let url = `${this.#api}/expiry-date/reminder/${id}`;
        return apiService.post(url)
    }
    changeNewsletter(data){
        let url = `${this.#api}/change-newsletter-subscription`;
        return apiService.post(url, data);
    }
    changeScoreNewsletter(data){
        let url = `${this.#api}/change-score-newsletter-subscription`;
        return apiService.post(url, data);
    }
}