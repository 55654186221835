<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Notifications
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                  label="Search by email title"
                  v-model="search.title"
                  v-on:keyup.enter="searchEmailActivities"
                  @input="search.title = $event !== null ? $event : ''"
                  clearable
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <v-menu
                  ref="menuStartDate"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.date_sent"
                      label="Date sent"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="search.date_sent"
                    outlined
                    dense
                    no-title
                    @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-btn
                  @click.prevent="searchEmailActivities"
                  class="btn btn-block btn-primary"
                  style="color: #fff;"
                  :loading="loading"
              >
                <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-skeleton-loader
            type="table-thead"
            v-if="loading"
        >
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="loading"
            type="table-row-divider@25"
        >
        </v-skeleton-loader>
        <div v-if="!loading && activities.length > 0">
          <table class="table">
            <thead>
            <tr class="text-left">
              <th class="px-3">Email subject</th>
              <th class="px-3">Email address</th>
              <th class="px-3">Sent</th>
              <th class="px-3">Options</th>
            </tr>
            </thead>
            <tbody>
            <template>
              <tr v-for="(item, index) in activities" :key="index" class="text-left">
                <td>{{item.title}}</td>
                <td>{{item.email_sent_to_address ? item.email_sent_to_address : ''}}</td>
                <td>{{item.formatted_date_sent}} <br>{{item.formatted_time}}</td>
                <td><a :href="item.email_content_url" target="_blank" >View</a></td>
              </tr>
            </template>
            </tbody>
          </table>
          <b-pagination
              v-if="activities.length > 0"
              class="pull-right mt-7"
              @input="getAllAccountHolderActivities"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              :disabled="loading"
              last-number
          ></b-pagination>
        </div>
        <div v-else class="text-center card-body">
        <NotFound
            :message="'Notifications not available'"
        ></NotFound>
      </div>
          <ShowEmailActivity ref="show-email-activity"></ShowEmailActivity>
      </div>
    </div>
  </v-app>
</template>

<script>
import NotFound from "@/view/pages/view/components/NotFound";
import UserNotificationService from "@/services/user-notification/UserNotificationService";
import ShowEmailActivity from "./ShowEmailActivity";

const userNotification = new UserNotificationService();
export default {
  name: "EmailActivity",
  components: {ShowEmailActivity, NotFound},
  data(){
    return{
      activities : [],
      userId: "",
      total: null,
      perPage: null,
      page: null,
      loading: true,
      menu: false,
      search: {
        title: "",
        date_sent: ""
      }
    }
  },
  mounted() {
    if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
      this.userId = this.$route.params.accountholderId;
    } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
      this.userId = this.$route.params.examinerId;
    } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
      this.userId = this.$route.params.supervisorId;
    } else {
      this.userId = this.$route.params.onlineMarkerId;
    }
    this.getAllAccountHolderActivities();
  },
  methods: {
    getAllAccountHolderActivities() {
      userNotification.getAllByUser(this.search, this.page, this.userId).then(response => {
        this.activities = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.lastPage = response.data.meta.last_page;
      }).catch(() => {}).finally(() => {
        this.loading = false;
      })
    },
    showActivityDetail(activity) {
      this.$refs['show-email-activity'].showActivityDetail(activity);
    },
    searchEmailActivities() {
      this.getAllAccountHolderActivities();
    }
  }
}
</script>

<style lang="css">
/*.v-badge__badge {*/
/*  border-radius: 5px !important;*/
/*  width: 45px !important;*/
/*  height: 45px !important;*/
/*  font-size: x-large !important;*/
/*  padding: 10px 6px !important;*/
/*}*/
</style>