<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Addresses
          </h3>
        </div>

        <div class="card-toolbar">
          <v-btn 
            @click="createAddress" 
            dark
            x-large
          >
            Add address
          </v-btn>
        </div>
      </div>

      <v-overlay :value="isUpdating">
        <v-progress-circular
          indeterminate
          color="dark"
          size="50"
        ></v-progress-circular>
      </v-overlay>

      <form class="form">
        <div class="card-body p-0 my-5">
          <div class="row">
            <div class="col-md-4">
              <v-skeleton-loader
                v-if="isLoading" 
                type=" card-heading,list-item-three-line, actions"
              >
              </v-skeleton-loader>
            </div>
            <div class="col-md-4">
              <v-skeleton-loader
                  v-if="isLoading" 
                  type=" card-heading,list-item-three-line, actions">
              </v-skeleton-loader>
            </div>
            <div class="col-md-4">
              <v-skeleton-loader
                  v-if="isLoading" 
                  type=" card-heading,list-item-three-line, actions">
              </v-skeleton-loader>
            </div>
          </div>

          <div v-if="! isLoading" >


            <div class="col-md-6 col-lg-6 col-xl-6" v-for="(item,i) in user_addresses" :key="i">
              <div class="card">
                <div class="p-5">
                  <div class="d-flex">
                    <div class="row">
                      <div class="col-md-12 text-right mb-2">
                        <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editAddress(item)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="useAddress(item.id,'billing_address',item.check_billing_address)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">{{ item.check_billing_address== true ? 'Remove billing' : 'Set billing' }}</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="useAddress(item.id,'shipping_address',item.check_shipping_address)"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-shipping-fast"></i>
                                </span>
                                <span class="navi-text">{{ item.check_shipping_address== true ? 'Remove postal' : 'Set postal' }}</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="deleteAddress(item.id,i)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-trash-alt"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </div>
                    </div>
                  </div>



                  <div class="separator separator-solid"></div>
                  <!--begin::Contact-->
                  <div class="">
                    <div class="d-flex align-items-center mt02 mb-1">
                      <span class="font-weight-bold mr-2">Address line 1:</span>
                     {{item.address_line_1}}
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Address line 2:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.address_line_2 ? item.address_line_2 : '' }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Suburb/town:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.suburb ? item.suburb : '' }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">State:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.country_id == 14 ? item.state_name : item.state }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Post code:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.post_code ? item.post_code : '' }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Country:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.country_name ? item.country_name : '' }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Type:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.type_text ? item.type_text : '' }}</span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="font-weight-bold mr-2">Business name:</span>
                      <span class="font-weight-regular text-hover-primary">{{item.business_name ? item.business_name : '' }}</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div>
                      <span
                               class="badge badge-info text-md text-right"
                               v-if="item.check_billing_address"
                           >
                         Billing address
                      </span>
                      <span
                          class="badge badge-success text-md text-right"
                          v-if="item.check_shipping_address"
                      >
                        &nbsp; Postal address
                      </span> &nbsp;

                    </div>
                  </div>
                </div>
                <!--end::Body-->
              </div>
            </div>
            <div class="col-md-12 text-center" v-if="user_addresses.length== 0">
              <span class="font-size-h4 mb-2">
                No address available
              </span>
            </div>
          </div>
          <create-address ref="create-address" :userId="userId" :user_type="user_type" @refresh="getAllAddress"></create-address>
        </div>

    </form>
    </div>
  </v-app>
</template>
<script>
import CreateAddress from "./CreateAddress";
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/setting/state/StateService";
import CountryService from "@/services/country/CountryService";
import UserSettingService from "@/services/user/UserSettingService";

const userSetting=new UserSettingService;
const country=new CountryService();
const state =new StateService;
const address=new UserAddressService;
export  default {
  components:{
    CreateAddress,
  },
  name: 'Addresses',
  props:['user_type'],
  data(){
    return{
      user_addresses:[],
      countries:[],
      total: null,
      perPage: null,
      lastPage: null,
      page: null,
      search:{
        user_id:'',
      },
      isUpdating: false,
      states:[],
      address:{
        address_line_1:'',
        address_line_2:'',
        suburb:'',
        state:'',
        state_id:'',
        post_code:'',
        country_id:14,
        type:'',
      },
      address_edit:false,
      errors:[],
      toggle:{
        stateList:true
      },
      isLoading:true,
    }
  },
  methods: {
    toggleCountry(){
      if (this.address.country_id!=14){
        this.toggle.stateList=false;
      }else{
        this.toggle.stateList=true;
      }
    },
    getAllAddress()
    {
      this.isLoading = true;
      this.search.user_id = this.userId;
      address
      .paginate(this.search, this.page)
      .then(response => {
        this.user_addresses = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      })
      .catch((err) => {
      })
      .finally(() => {
        this.isLoading=false;
      });
    },

    editAddress(item){
      this.$refs['create-address'].editAddress(item);
    },
    close(){
      this.address_edit=false;
    },
    getAllState()
    {
      state
          .all()
          .then(response => {
            this.states = response.data;
          })
          .catch((err) => {
          });
    },
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
          })
    },
    disableEdit(){
      this.close();
    },
    createAddress(){
      this.disableEdit();
      this.$refs['create-address'].createUserAddress(this.scoreId);
    },
    deleteAddress(addressId,index){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isUpdating = true;
            address
                .delete(addressId)
                .then((response) => {
                  this.user_addresses.splice(index,1);
                  this.$snotify.success("Address deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                  this.isUpdating = false;
                });
          }
        },
      });
    },
    useAddress(addressId,addressType,status){
      let data={};
      if(addressType == 'billing_address'){
        this.$confirm({
          message: `Are you sure that you want use as Billing address ?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              this.isUpdating = true;
              if(status==true){
                data = {'default_billing_address_id':null};
              }else {
                data = {'default_billing_address_id':addressId};
              }
              data['user_id'] = this.userId;
              data['user_type'] = this.user_type;
              userSetting
                  .create(data)
                  .then(response => {
                    // this.$emit("update-accountholder");
                    this.user_addresses=[]
                    this.getAllAddress();
                    if(addressType == 'billing_address') {
                      this.$snotify.success("Address updated");
                    }else{
                      this.$snotify.success("Address updated");
                    }
                  })
                  .catch(error => {
                    this.errors = error.errors;
                  })
                  .finally(() => {
                    this.isUpdating = false;
                  });
            }
          },
        });
      }else if(addressType == 'shipping_address'){
        this.$confirm({
          message: `Are you sure that you want use as Shipping Address ?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              this.isUpdating = true;
              if(status==true) {
                data = {'default_shipping_address_id': null};
              }else {
                data = {'default_shipping_address_id': addressId};
              }
              data['user_id'] = this.userId;
              data['user_type'] = this.user_type;
              userSetting
                  .create(data)
                  .then(response => {
                    // this.$emit("update-accountholder");
                    this.user_addresses=[]
                    this.getAllAddress();
                    if(addressType == 'billing_address') {
                      this.$snotify.success("Address updated");
                    }else{
                      this.$snotify.success("Address updated");
                    }
                  })
                  .catch(error => {
                    this.errors = error.errors;
                  })
                  .finally(() => {
                    this.isUpdating = false;
                  });
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getAllState();
    this.getCountries();
    this.getAllAddress();
  },
  computed:{
    userId() {
      if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
        return this.$route.params.accountholderId;
      } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
        return this.$route.params.examinerId;
      } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
        return this.$route.params.supervisorId;
      } else {
        return this.$route.params.onlineMarkerId;
      }
    },
  }
}
</script>