<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            AMEB Award candidates
          </h3>
        </div>
      </div>
      <form class="form">

        <div class="card-body p-0 my-5">
          <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <div class="row">
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                    label="Enrolment key"
                    v-model="search.exam_key"
                    outlined
                    dense  v-on:keyup.enter="searchCandidate"
                    @input="search.exam_key = $event !== null ? $event : ''"
                    clearable
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6" md="2">
                <v-text-field
                    label="First name"
                    v-model="search.first_name"
                    outlined
                    dense  v-on:keyup.enter="searchCandidate"
                    @input="search.first_name = $event !== null ? $event : ''"
                    clearable
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6" md="2">
                <v-text-field
                    label="Middle name"
                    v-model="search.middle_name"
                    outlined  v-on:keyup.enter="searchCandidate"
                    dense
                    @input="search.middle_name = $event !== null ? $event : ''"
                    clearable
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6" md="2">
                <v-text-field
                    label="Surname"
                    v-model="search.last_name"
                    outlined
                    dense  v-on:keyup.enter="searchCandidate"
                    @input="search.last_name = $event !== null ? $event : ''"
                    clearable
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6" md="2">
                <v-text-field
                    label="UniqueID"
                    v-model="search.exam_identifier"
                    outlined
                    dense  v-on:keyup.enter="searchCandidate"
                    @input="search.exam_identifier = $event !== null ? $event : ''"
                    clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="2">
                <v-btn @click.prevent="searchCandidate()"  :loading="isLoading" class="btn btn-primary btn-block" dark>
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </div>

          </div>
          <div class="table-responsive">
            <v-skeleton-loader
                type="table-thead"
                v-if="isLoading">
            </v-skeleton-loader>

            <v-skeleton-loader
                v-if="isLoading"
                type="table-row-divider@25">
            </v-skeleton-loader>
            <table class="table">
              <thead>
              <tr class="text-left">
                <th class="px-3">Name</th>
                <th class="px-3">Enrolment</th>
                <th class="px-3">Other info </th>
                <th class="px-3">Status </th>
                <th class="pr-3 text-center">Options</th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr v-if="course_candidates.length != 0" v-for="(item, index) in course_candidates" :key="index">
                  <td class="px-3">
                    <div class="mb-1">
                      {{item.first_name}} {{item.middle_name}} {{item.last_name}}
                    </div>
                  </td>
                  <td class="px-3">
                    <div class="font-weight-bold ">
                      {{item.exam_name}}
                    </div>
                    <a href="#" @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                      <u> {{item.exam_key}} </u>
                    </a>
                  </td>
                  <td class="px-3">
                    <div v-html="item.additional_info"></div>
                  </td>
                  <td class="px-3">
                    <div v-html="item.enrolment_status_badge"></div>
                    <div class="mt-2" v-html="item.exam_status_badge"></div>
                  </td>
                  <td class="pr-0 text-left">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link"  @click="getCandidateSummary(item.exam_key)">
                                            <span class="navi-icon">
                                                <v-icon color="darken-2">fas fa-eye</v-icon>
                                            </span>
                              <span class="navi-text">View Candidate Information</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="course_candidates.length == 0">
                  <td class="px-3 text-center" colspan="5">
                    No Result Found
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <b-pagination  :disabled="isLoading"
                v-if="course_candidates.length > 0"
                class="pull-right mt-7"
                @input="getCourseCandidates"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
            ></b-pagination>
          </div>
        </div>
      </form>

    </div>
  </v-app>
</template>
<script>
import CandidateService from "@/services/candidate/CandidateService";
const candidate = new CandidateService();
export default {

  data() {
    return {
      course_candidates: [],
      total: null,
      title: '',
      perPage: null,
      lastPage: null,
      page: 1,
      search:{
        type: '',
        owner_id:'',
        exam_key:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        exam_identifier:'',
      },
      isLoading:false,
      panel: '',
    }
  },
  methods:{
    getAmebAwardCandidates(){
      this.isLoading=true;
      this.search.type = 'ameb_award';
      this.search.owner_id = this.ownerId;
      candidate
          .paginate(this.search, this.page)
          .then(response => {
            this.course_candidates = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.lastPage = response.data.meta.last_page;
            this.isLoading=false;
          })
          .catch((err) => {
          });
    },
    searchCandidate(){
      this.getAmebAwardCandidates();
    },
    disableEdit(){
      this.close();
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    }
  },
  computed:{
    ownerId() {
      if(this.$route.params.accountholderId)
        return this.$route.params.accountholderId;
      else if(this.$route.params.examinerId)
        return this.$route.params.examinerId;
      else if(this.$route.params.onlineMarkerId)
        return this.$route.params.onlineMarkerId;
      else
        return this.$route.params.supervisorId;
    },
  },
  mounted() {
    this.getAmebAwardCandidates();
  }
}
</script>
