<template>
  <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      scrollable
      max-width="800"
  >
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>Add new invoice</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-select dense outlined v-model="add_type" :items="add_types" item-value="value" item-text="text" label="Select Type" :menu-props="{ bottom: true, offsetY: true }">
              </v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-row v-for="(row,index) in rows" >
                <v-col cols="12" md="12">
                  <v-autocomplete @change="findTransactionHeadingsDetail(index)" dense outlined  v-model="row.financial_transaction_heading" :error="$v.rows.$each[index].financial_transaction_heading_id.$error" :items="transaction_headers" item-text="name" return-object>
                    <template v-slot:label>
                      Select fee <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.rows.$each[index].financial_transaction_heading_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox @click="userNegativeNumber(index)" v-model="row.is_negative_number">
                    <template v-slot:label>
                      <div>
                       Is this a refund amount?
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field dense outlined label="Amount" v-model="row.amount"  @keypress="onlyNumber" :error="$v.rows.$each[index].amount.$error" @keyup="sumTotal"></v-text-field>
                  <span class="text-danger" v-if="$v.rows.$each[index].amount.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="1" >
                  <v-btn @click="addField" class="mx-2" fab dark small color="blue">
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>

                </v-col>
                <v-col cols="1" v-show="rows.length > 1 && index!=0">
                  <v-btn  @click="removeField(index)"  class="mx-2" fab dark small color="red">
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <h6 class="font-weight-medium font-weight-bold">Total Amount : {{scoreDetail.currency_symbol}} {{amount ? amount :'0' }}</h6>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete  clearable dense outlined :items="candidates"  v-model="invoice.candidate_id" :error="$v.invoice.candidate_id.$error"
                              :search-input.sync="candidateSearch" item-text="customize_text_value" item-value="id">
                <template v-slot:label>
                  Select Candidate <span class="text-danger">*</span>
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.customize_text_value }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item">
                      {{ data.item.customize_text_value }}
                    </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title> {{ data.item.customize_text_value }} </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.invoice.candidate_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="add_type=='add_by_admin'">
              <v-text-field outlined dense
                            v-model="invoice.payer_name">
                <template v-slot:label>
                  Payer name
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="add_type=='add_by_admin'">
              <v-checkbox v-model="invoice.auto_generate">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Automatically generate random transaction Id
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="add_type=='add_by_admin' && !invoice.auto_generate">
              <v-text-field type="text"  outlined label="Transaction Id" dense v-model="invoice.transaction_id" :error="$v.invoice.transaction_id.$error">
                <template v-slot:label>
                  Transaction Id <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.invoice.transaction_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="6" md="12" v-if="add_type=='add_by_admin'">
              <v-menu
                  ref="menuDate"
                  v-model="menuPaidTime"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y  full-width="true"
                  min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="invoice.order_paid_timestamp" label="Order paid"
                      readonly outlined dense
                      v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="invoice.order_paid_timestamp"
                    outlined dense no-title
                    @input="menuPaidTime = false">
                </v-date-picker>
              </v-menu>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            class="cancel-btn"
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn @click="AddToCartWithoutPayment"
               v-if="add_type=='add_to_cart'"
               color="btn btn-primary"
               :loading="loading" :disabled="addToCartDisabled"
               x-large dark>
          Confirm
        </v-btn>
        <v-btn @click="addToCartWithPayment"
               color="btn btn-primary" v-if="add_type=='add_by_admin'"
               dark  :loading="loading"
               x-large
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import TransactionHeadingService from "@/services/transaction-heading/TransactionHeadingService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import InvoiceService from "@/services/invoice/InvoiceService";
import CandidateService from "@/services/candidate/CandidateService";
import {required,requiredIf,numeric} from "vuelidate/lib/validators";

const candidate=new CandidateService();
const invoice=new InvoiceService();
const score=new ScoreService();
const accountHolder=new AccountHolderService();
const transactionHeader=new TransactionHeadingService();
export default {
  props:['userId'],
  validations:{
    invoice:{
      user_id:{required},
      candidate_id:{required},
      order_paid_timestamp: {
        required: requiredIf(function () {
          return this.add_type=='add_by_admin';
        })
      },

      transaction_id: {
        required: requiredIf(function () {
          return !this.invoice.auto_generate && this.add_type=='add_by_admin';
        })
      },
    },
    rows: {
      $each: {
        financial_transaction_heading_id: {required},
        amount: {required},

      }
    },
  },
  data(){
    return{
      dialog:false,
      addToCartDisabled:false,
      menuPaidTime:false,
      invoice:{
        user_id:'',
        candidate_id:'',
        auto_generate:true,
        transaction_id:'',
        payer_name:'',
        payment_method:'other',
        order_paid_timestamp:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      transaction_headers:[],
      accountHolderDetail:{},
      candidateDetail:{},
      scoreId:'',
      candidates:[],
      candidateSearch:'',
      loading:false,
      amount:'',
      financial_transaction_heading:{},
      add_types:[
        {text:'Add to Enroller\'s Cart',value:'add_to_cart'},
        {text:'Add from admin',value:'add_by_admin'},
      ],
      add_type:'add_by_admin',
      payment_methods:[
        { text: "Card", value: "card" },
        { text: "Paypal", value: "paypal" },
        { text: "SchoolPay", value: "schoolpay" },
        { text: "Alepay", value: "alepay" },
        { text: "Other", value: "other" }
      ],
      scoreDetail:{},
      rows:[
        {
          financial_transaction_heading:{},
          financial_transaction_heading_id:'',
          amount:'',
          is_negative_number:'',
        },
      ],
    }
  },
  methods:{
    createInvoice(){
      this.reset();
      this.dialog=true;
      this.getAccountHolderDetail();

    },
    closeDialog(){
      this.dialog=false;
    },
    getAccountHolderDetail(){
      accountHolder
          .show(this.userId)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {

          });
    },
    //Transaction Header
    getAllTransactionHeadings() {
      transactionHeader
          .getAllActive({'score_id':this.scoreId})
          .then((response) => {
            this.transaction_headers=response.data.transactionHeadings;
          })
          .catch(err => {

          }).finally(() => {

      })
    },
    findTransactionHeadingsDetail(index){
      this.rows[index].financial_transaction_heading_id=this.rows[index].financial_transaction_heading.id;
      this.rows[index].amount=this.rows[index].financial_transaction_heading.amount;
      if(this.rows[index].is_negative_number){
        this.rows[index].amount=-this.rows[index].amount;
      }
      if(!this.rows[index].is_negative_number){
        this.rows[index].amount==Math.abs(this.rows[index].amount);
      }
      this.sumTotal();
    },
    userNegativeNumber(index){
      if(this.rows[index].is_negative_number){
        this.rows[index].amount=-this.rows[index].amount;
      }
      if(!this.rows[index].is_negative_number){
        this.rows[index].amount=Math.abs(this.rows[index].amount);
      }
      this.sumTotal()
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
             this.getAllTransactionHeadings();
            })
            .catch(err => {

            }).finally(() => {

        });
      }
    },
    AddToCartWithoutPayment(){
      this.invoice.user_id=this.userId;
      this.invoice.transaction_id=null;
      this.invoice.order_paid_timestamp=null;
      this.invoice.payment_method=null;
      this.invoice.payer_name=null;
      this.invoice['transaction_info']=this.rows;
      this.$v.$touch()
      if (this.$v.$error) {
        if(this.$v.invoice.user_id.$error){
          this.$snotify.error('Select Accountholder to proceed further');
        }
        setTimeout(() => {
          this.$v.$reset()
        }, 5000);
      } else {
        this.loading = true;
        invoice
            .createWithoutPayment(this.invoice)
            .then((response) => {
              if (response.data.status == 'SUCCESS') {
                this.$snotify.success(response.data.message);
              }
              if (response.data.status == 'ERROR') {
                this.$snotify.error(response.data.message);
              }
              this.$emit('refresh');
              this.closeDialog();
            }).catch(err => {

        }).finally(() => {
          this.loading = false;
        });
      }
    },
    addToCartWithPayment(){
      this.invoice.user_id=this.userId;
      this.invoice['transaction_info']=this.rows;
      this.$v.$touch()
      if (this.$v.$error) {
        if(this.$v.invoice.user_id.$error){
          this.$snotify.error('Select Accountholder to proceed further');
        }
        setTimeout(() => {

          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        invoice
            .createWithPayment(this.invoice)
            .then((response) => {
              if (response.data.status == 'SUCCESS') {
                this.$snotify.success(response.data.message);
              }
              if (response.data.status == 'ERROR') {
                this.$snotify.error(response.data.message);
              }
              this.$emit('refresh');
              this.closeDialog();
            })
            .catch(err => {

            }).finally(() => {
          this.loading = false;
        });
      }
    },
    getCandidateDetail(){

    },
    reset(){
      this.add_type='add_by_admin';
      this.candidateSearch='';
      this.invoice={
        financial_transaction_heading_id:'',
        user_id:'',
        candidate_id:'',
        amount:'',
        auto_generate:true,
        transaction_id:'',
        payer_name:'',
        payment_method:'other',
        order_paid_timestamp:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      };
      this.rows=[
        {
          financial_transaction_heading:{},
          financial_transaction_heading_id:'',
          amount:'',
          is_negative_number:'',
        },
      ];
      this.accountHolderDetail={};
      this.candidateDetail={};
      this.$v.$reset();
      this.financial_transaction_heading={};
    },
    onlyNumber ($event) {

      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    addField() {
      this.rows.push({ amount: "" });
    },
    removeField(index) {
      if(index!=0){
        this.rows.splice(index, 1);
      }
    },
    sumTotal() {
      let total_amount = 0;
      this.rows.forEach(val => {
        total_amount += Number(val.amount);
        //or if you pass float numbers , use parseFloat()
      });
      this.amount=total_amount;
      const result = this.rows.filter(val => val.is_negative_number==true);
      if(result.length>0){
        this.addToCartDisabled=true;
      }
      if(result.length==0){
        this.addToCartDisabled=false;
      }
    }

  },
  mounted() {

  },
  watch: {
    candidateSearch(val) {
      let data={
        'user_id':this.userId,info:val,
        'is_cart_complete':1,
        'no_enrolment_date' : 1
      };
      candidate
            .findByExamKey(data)
            .then((response) => {
              let candidates=[];
              this.candidates = response.data.candidates;
            })
            .catch(err => {

            })
            .finally(() => (this.isLoading = false))

    },

  },
}
</script>