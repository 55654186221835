import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SupportTicketService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/support-ticket';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getAllCloseTicket(data = {}, index = null) {
        let url = `${this.#api}/get/all-closed-ticket`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    closeTicket(id, data) {
        let url = `${this.#api}/${id}/close-ticket`
        return apiService.post(url, data)
    }

    getUnReadTicket(type) {
        let url = `${this.#api}/get/unread-ticket?type=${type}`
        return apiService.get(url)
    }

    markAsReadTicket(data) {
        let url = `${this.#api}/mark-as-read-ticket`
        return apiService.post(url, data)
    }

    markAsUnReadTicket(data) {
        let url = `${this.#api}/mark-as-unread-ticket`
        return apiService.post(url, data)
    }

    markAllReadTicket() {
        let url = `${this.#api}/mark-all-read-ticket`
        return apiService.post(url)
    }

    assignedTicket(id, data) {
        let url = `${this.#api}/${id}/assigned-ticket`
        return apiService.post(url, data)
    }

    unassignedTicket(id) {
        let url = `${this.#api}/${id}/unassigned-ticket`
        return apiService.post(url)
    }

    getAssignedTicket(data = {}, index = null) {
        let url = `${this.#api}/get/assigned-ticket`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getAllCandidateTicket(candidateId, data = {}, index = null) {
        let url = `${this.#api}/get/all/${candidateId}/candidate-ticket`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    approvedRefund(ticketId,data={}){
        let url = `${this.#api}/${ticketId}/approved-refund`;
        return apiService.post(url, data)
    }
    manageRefund(ticketId,data={}){
        let url = `${this.#api}/${ticketId}/manage-refund`;
        return apiService.post(url, data)
    }

}