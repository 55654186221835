import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class InvoiceService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/invoice';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    createWithoutPayment(data={}){
        let url = `${this.#api}/create/without-payment`;
        let param ={
            params: data
        }
        return apiService.query(url, param)
    }
    createWithPayment(data={}){
        let url = `${this.#api}/create/with-payment`;
        let param ={
            params: data
        }
        return apiService.query(url, param)
    }
}