<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Information log
          </h3>
        </div>
      </div>
      <div class="card-body">
        <v-skeleton-loader
            type="table-thead"
            v-if="loading"
        >
        </v-skeleton-loader>
        <v-skeleton-loader
            v-if="loading"
            type="table-row-divider@25"
        >
        </v-skeleton-loader>
        <div class="table-responsive" v-if="!loading && logs.length > 0">
          <table class="table"
                 id="kt_advance_table_widget_4">
            <thead>
            <tr class="text-left">
              <th>Log</th>
              <th class="pr-3">Options</th>
            </tr>
            </thead>
            <tbody>
            <template>
              <tr v-for="(item, index) in logs" :key="index">
                <td>
                  <code>{{item.title}}</code>
                  <span class="ml-2" v-if="item.admin_user.length > 0"> by {{item.admin_user[0].first_name}} {{item.admin_user[0].last_name}} on</span> <code>{{item.formatted_log_date}}</code>
                </td>
                <td>
                  <v-btn :color="item.compared_data.length == 0 ? 'grey lighten-1' : ''"  x-large dark @click="showLogDetail(item)">View detail</v-btn>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <b-pagination
              v-if="logs.length > 0"
              class="pull-right mt-7"
              @input="getAllLogs"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              :disabled="loading"
              last-number
          ></b-pagination>
          </div>

        <LogDetail ref="show-log-detail"></LogDetail>
      </div>
    </div>
  </v-app>
</template>

<script>
import LogDetail from "./LogDetail";
import NotFound from "@/view/pages/view/components/NotFound";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import LogService from "@/services/log/LogService";

const log = new LogService()
export default {
  name: "InformationLog",
  components: {LogDetail, NotFound},
  data(){
    return{
      logs : [],
      userId: "",
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      search: {
        title: "",
        date_sent: "",
        table_by: [],
        user_id: '',
        table_id: '',
      }
    }
  },
  mounted() {
    if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
      this.userId = this.$route.params.accountholderId;
      this.search.table_by.push('users', 'user_addresses');
      this.search.user_id = this.userId;
      this.search.table_id = this.userId;
    } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
      this.userId = this.$route.params.examinerId;
      this.search.table_by.push('users', 'user_addresses');
      this.search.user_id = this.userId;
      this.search.table_id = this.userId;
    } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
      this.userId = this.$route.params.supervisorId;
      this.search.table_by.push('users', 'user_addresses');
      this.search.user_id = this.userId;
      this.search.table_id = this.userId;
    } else {
      this.userId = this.$route.params.onlineMarkerId;
      this.search.table_by.push('users', 'user_addresses');
      this.search.user_id = this.userId;
      this.search.table_id = this.userId;
    }
    this.getAllLogs();
  },
  methods: {
    getAllLogs(){
      this.loading = true;
      log
      .paginate(this.search)
      .then((response) => {
        this.page = response.data.logs.current_page;
        this.total = response.data.logs.total;
        this.perPage = response.data.logs.per_page;
        this.logs = response.data.logs.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    showLogDetail(log){
      if(log.compared_data.length > 0) {
        this.$refs['show-log-detail'].showLogDetail(log);
      }
    }
  }
}
</script>

<style scoped>

</style>