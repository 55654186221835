<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="900"
    >
      <v-card>
        <v-card-title>
          <span >{{activity.title}}</span>
        </v-card-title>
        <v-card-subtitle class="ml-1">
          <span class="font-size-md"> By {{activity.sentBy}} on {{activity.formatted_date_sent}}</span>
          <v-divider></v-divider>
        </v-card-subtitle>
        <v-card-text>
         <iframe :src="activity.email_content_url" width="825px" height="750px"></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-danger text-white"
              text
              @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "ShowEmailActivity",
  data(){
    return{
      dialog: false,
      activity : {},
    }
  },
  methods:{
    showActivityDetail(activity){
      this.activity = activity;
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
    },
  }
}
</script>

<style scoped>

</style>