<template>
    <div class="row">
      <div class="col-md-12">
         <img src="/media/no-data-icon.jpg" style="width: 100px" class="mb-10"/>
        <h3 v-html="message">No information available to display</h3>
      </div>
    </div>
</template>

<script>
export default {
    props: ['message']   
}
</script>
