<template>
  <div>
    <div class="d-flex align-items-center">
      <div>
        <span class="font-weight-bolder font-size-h5 text-dark-75">
          {{userDetail.full_name}}
        </span>

        <a href="#" @click="loginAsAccountHolder(userDetail.id)" class="ml-2 badge badge-info text-white"> Log in as user</a>
        <p class="mb-2">{{userDetail.score_name}}</p>
        <span class="ml-2 badge badge-role" v-if="userDetail.is_account_holder">Enroller</span>
        <span class="ml-2 badge badge-role" v-if="userDetail.is_supervisor">Supervisor</span>
        <span class="ml-2 badge badge-role" v-if="userDetail.is_examiner">Examiner</span>
        <span class="ml-2 badge badge-role" v-if="userDetail.is_online_marker">Online marker</span>
        <a @click="viewApplicationSummary(userDetail.id)" class="ml-2 badge badge-primary text-white" v-if="userDetail.school_pay_enabled && userDetail.is_school_account">SchoolPay</a>

      </div>
    </div>
    <hr>
    <div class="py-4">
      <div class="d-flex align-items-center mb-1">
        <span class="font-weight-bold mr-2">Enroller type:</span>
        <span class="font-weight-regular" v-copy="userDetail.role_text ? userDetail.role_text : ''">{{userDetail.role_text ? userDetail.role_text : 'NA'}}</span>
      </div>

      <div class="d-flex align-items-center mb-1">
        <span class="font-weight-bold mr-2">UniqueID: </span>
        <span class="font-weight-regular" v-copy="userDetail.unique_identifier">{{userDetail.unique_identifier ? userDetail.unique_identifier : 'NA'}}</span>
      </div>

      <div class="d-flex align-items-center mb-1">
        <span class="font-weight-bold mr-2">Email: </span>
        <span class="font-weight-regular text-hover-primary" v-copy="userDetail.email">{{userDetail.email ? userDetail.email : 'NA'}}</span>
      </div>

      <div class="d-flex align-items-center  mb-1">
        <span class="font-weight-bold mr-2">Preferred Phone: </span>
        <span class="font-weight-regular" v-copy="userDetail.preferred_phone">{{userDetail.preferred_phone ? userDetail.preferred_phone : 'NA'}}</span>
      </div>

      <div class="d-flex align-items-center mb-1" v-if="userType === 'examiner'">
        <span class="font-weight-bold mr-2">Member since : </span>
        <span class="font-weight-regular">{{userDetail.member_since}}</span>
      </div>

      <div class="d-flex align-items-center justify-content-left mb-1" v-if="userType === 'enroller'">
        <span class="font-weight-bold mr-2">Total candidates: </span>
        <span class="label label-light-danger label-rounded font-weight-bold">
                    {{userDetail.total_candidate ? userDetail.total_candidate : '0' }}</span>
      </div>

      <div class="d-flex align-items-center mb-2">
        <span class="font-weight-bold mr-2">Enroller status:</span>
        <span class="badge " v-bind:class="{ 'badge-success': userDetail.is_active, 'badge-danger': !userDetail.is_active }">{{ userDetail.is_active ? "Active" : "Inactive" }}</span>
      </div>

      <div class="d-flex align-items-center mb-2" v-if="userDetail.is_examiner">
        <span class="font-weight-bold mr-2">Examiner status:</span>
        <span class="badge " v-bind:class="{ 'badge-success': userDetail.is_active_examiner, 'badge-danger': !userDetail.is_active_examiner }">{{ userDetail.is_active_examiner ? "Active" : "Inactive" }}</span>
      </div>

      <div class="d-flex align-items-center mb-2"  v-if="userDetail.is_supervisor">
        <span class="font-weight-bold mr-2">Supervisor status:</span>
        <span class="badge " v-bind:class="{ 'badge-success': userDetail.is_active_supervisor, 'badge-danger': !userDetail.is_active_supervisor }">{{ userDetail.is_active_supervisor ? "Active" : "Inactive" }}</span>
      </div>

      <div class="d-flex align-items-center mb-2"  v-if="userDetail.is_online_marker">
        <span class="font-weight-bold mr-2">Marker status:</span>
        <span class="badge " v-bind:class="{ 'badge-success': userDetail.is_active_online_marker, 'badge-danger': !userDetail.is_active_online_marker }">{{ userDetail.is_active_online_marker ? "Active" : "Inactive" }}</span>
      </div>
      <div class="d-flex align-items-center mb-2">
        <span class="font-weight-bold mr-2">Notification preference:</span>
        <span class="badge badge-info">{{ userDetail.notification_method  }}</span>
      </div>
      <div class="form-group row">
        <div class="col-lg-9 col-xl-6">

        </div>
      </div>
    </div>
    <hr>

    <school-pay-application-detail ref="application-detail" ></school-pay-application-detail>
  </div>
</template>

<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import SchoolPayApplicationDetail from "@/view/pages/view/school/pay/ApplicationDetail";

const accountholder = new AccountholderService();
export default {
  components: {SchoolPayApplicationDetail},
  name: "SideProfile",
  props: ['userDetail'],
  methods:{
    loginAsAccountHolder(accountHolderId)
    {
      accountholder.loginAsAccountHolder(accountHolderId).then(response=>{
        if(response.data.status=='OK')
        {
          let popup =  window.open(response.data.url,'_blank');
          if (!popup) {
            this.$snotify.warning('Please enable popup');
          }
        }
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    viewApplicationSummary(accountHolderId){
      this.$refs['application-detail'].openDialog(accountHolderId);
    },
  },
  computed: {
    userType() {
      if(this.$route.name.includes('accountholder')) {
        return "Enroller";
      } else if(this.$route.name.includes('examiner')) {
        return "Examiner";
      } else if(this.$route.name.includes('online-marker')) {
        return "Online Marker";
      } else {
        return "Supervisor";
      }
    },

  }
}
</script>

<style scoped>

</style>