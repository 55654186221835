<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Tickets
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">View tickets</span>
      </div>
    </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <div class="row">
            <v-col cols="12" sm="6" md="2">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.date_from"
                      label="View from date"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      v-on:keyup.enter="searchSupportTicket()"
                      @input="search.date_from = $event !== null ? $event : ''"
                      :error="checkDateFrom"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="search.date_from"
                    no-title
                    @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <!--                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>-->
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.date_to"
                      label="View up to date"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      v-on:keyup.enter="searchSupportTicket()"
                      :error="checkDateTo"
                      @input="search.date_to = $event !== null ? $event : ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="search.date_to"
                    no-title
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu><br>
              <!--                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>-->
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                  label="Request title"
                  v-model="search.name"
                  outlined
                  dense
                  @input="search.name = $event !== null ? $event : ''"
                  clearable
                  v-on:keyup.enter="searchSupportTicket()"
              ></v-text-field>
            </v-col>
<!--            <v-col cols="12" sm="6" md="2">-->
<!--              <v-select-->
<!--                  label="Type"-->
<!--                  v-model="search.type"-->
<!--                  :items="types"-->
<!--                  item-text="name"-->
<!--                  item-value="value"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  @input="search.type = $event !== null ? $event : ''"-->
<!--                  v-on:keyup.enter="searchSupportTicket()"-->
<!--                  clearable-->
<!--              ></v-select>-->
<!--            </v-col>-->

            <v-col cols="12" sm="6" md="3">
              <v-select
                  label="Read/unread"
                  v-model="search.is_read"
                  :items="read_types"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  @input="search.is_read = $event !== null ? $event : ''"
                  v-on:keyup.enter="searchSupportTicket()"
                  clearable
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-text-field
                  label="Assigned to"
                  v-model="search.assignedTo"
                  outlined
                  dense
                  @input="search.assignedTo = $event !== null ? $event : ''"
                  clearable
                  v-on:keyup.enter="searchSupportTicket()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-text-field
                  label="Assigned by"
                  v-model="search.assignedBy"
                  outlined
                  dense
                  @input="search.assignedBy = $event !== null ? $event : ''"
                  clearable
                  v-on:keyup.enter="searchSupportTicket()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-select
                  v-model="search.assigned_type"
                  :items="assignedTypes"
                  label="Assigned type"
                  item-text="name"
                  item-value="value"
                  outlined
                  clearable
                  v-on:keyup.enter="searchSupportTicket()"
                  dense
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.assigned_date"
                      label="Assigned date"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="search.assigned_date"
                    outlined
                    dense
                    no-title
                    @input="menu2 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                  :items="status"
                  v-model="search.is_active"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  outlined
                  clearable
                  v-on:keyup.enter="searchSupportTicket()"
                  dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn
                  @click="searchSupportTicket()"
                  class="mt-1 btn btn-block btn-primary"
                  style="color: #fff"
                  :loading="loading"
              >
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </div>

        </div>

        <div class="table-responsive">

          <v-skeleton-loader
              type="table-thead"
              v-if="loading"
          >
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="loading"
              type="table-row-divider@25"
          >
          </v-skeleton-loader>

          <table class="table">
            <thead>
            <tr class="text-left">
              <th class="px-3">Request</th>
              <th class="px-3">Summary</th>
              <th class="px-3">Managed by</th>
              <th class="px-3">Requested date</th>
              <th class="px-3">Status</th>
            </tr>
            </thead>
            <tbody class="mt-5">
            <template>
              <tr v-bind:class="{ 'ameb__bg-light-blue': !item.is_read_admin }" v-for="(item, index) in support_tickets" :key="index">
                <td class="px-2">
                  <a @click="viewSupportThread(item)" class="d-flex align-items-center">
                    <p class="text-primary font-weight-bolder text-hover-primary mb-1">{{ item.name }}</p>
                  </a>
                </td>
                <td class="px-3">
                  <p> {{item.category_name }}</p>
                  <span class="badge badge-success"> {{item.score }}</span>
                </td>
                <td class="px-3" v-if="item.assignedTo && item.assignedBy">
                   <span class="mb-1 d-flex align-items-center">
                        <b v-if="item.formatted_assigned_date">Assigned date: </b> {{item.formatted_assigned_date ? item.formatted_assigned_date : '' }}
                      </span>
                      <span class="mb-1 mt-2 d-flex align-items-center">
                        <b v-if="item.assignedTo">Assigned to: </b> {{item.assignedTo ? item.assignedTo : '' }}
                      </span>
                  <span class="mb-1 mt-2 d-flex align-items-center">
                        <b v-if="item.assignedBy">Assigned by: </b> {{item.assignedBy ? item.assignedBy : '' }}
                      </span>

                </td>
                <td v-else>
                  <h5>NA</h5>
                </td>
                <td class="px-3">
                  <p  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                    {{ item.formatted_created_at ? item.formatted_created_at : '--'}}
                  </p>
                  <span class="fw-bold">{{item.readable_created_at}}</span>
                </td>
                <td class="px-3">
                     <span class="badge" v-bind:class="{ 'badge-success': item.ticket_status == 'In Progress', 'badge-danger': item.ticket_status != 'In Progress'}">{{ item.ticket_status }}</span>
                </td>
              </tr>
              <tr v-if="support_tickets.length == 0">
                <td colspan="10" class="text-center">
                  <strong>No tickets available</strong>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <b-pagination
              v-if="support_tickets.length > 0"
              class="pull-right mt-7"
              @input="getAllSupportTicket"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              :disabled="loading"
          ></b-pagination>
        </div>
      </div>
  </div>
</template>

<script>

import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import ScoreService from "@/services/score/score/ScoreService";

const supportTicket=new SupportTicketService();
const score = new ScoreService();

export default {
  name: "SupportTicket",
  data() {
    return {
      currentUser:{},
      support_tickets:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      isLoading: false,
      scores: [],
      search:{
        date_to: '',
        date_from: '',
        name: '',
        type: '',
        user_id: '',
        is_read: '',
        score_id: '',
        assignedTo: '',
        assignedBy: '',
        assigned_date: '',
        assigned_type: '',
        is_active: '',
      },
      assignedTypes: [
        {name: 'Federal', value: 'federal'},
        {name: 'Score', value: 'score'}
      ],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read', value: '1'},
        {name: 'Unread', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Extension', value: 'extension'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Appeal', value: 'appeal'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Shipment', value: 'shipment'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  mounted() {
    this.search.user_id = this.userId;
    this.getAllSupportTicket();
  },
  computed: {
    checkDateFrom() {
      return this.search.date_to != '' && this.search.date_from == '';
    },
    checkDateTo() {
      return this.search.date_from != '' && this.search.date_to == '';
    },
    userId() {
      if(this.$route.params.accountholderId)
        return this.$route.params.accountholderId;
      else if(this.$route.params.examinerId)
        return this.$route.params.examinerId;
      else if(this.$route.params.onlineMarkerId)
        return this.$route.params.onlineMarkerId;
      else if(this.$route.params.supervisorId)
        return this.$route.params.supervisorId;
      else
        return this.$route.params.accountholderId;
    },
  },
  methods:{

    getAllSupportTicket(){
      this.loading =  true;
      supportTicket
          .paginate(this.search,this.page)
          .then(response => {
            this.support_tickets=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.getUnReadTicket();
          })
          .catch((err) => {});
    },
    searchSupportTicket(){
      this.getAllSupportTicket();
    },
    viewSupportThread(ticket){
      if(!ticket.is_read_admin) {
        ticket.is_read_admin = true;
        supportTicket.update(ticket.id, ticket).then(response =>{
          this.getAllSupportTicket();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.$router.push({
        name:'support-ticket-view',
        params:{supportTicketId: ticket.id}
      });
    }
  }
}
</script>

<style scoped>

</style>