import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;
export default class UserNotificationService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user-notification';
    }

    getAllByUser(data={},index = null, id) {
        let url = `${this.#api}/get-all-user/${id}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAllAdminNotifications(data={},index = null, adminUserId) {
        let url = `${this.#api}/${adminUserId}/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAdminNotifications(adminUserId, data = {}) {
        let url = `${this.#api}/${adminUserId}/get`;
        let param ={
            params: data
        }
        return apiService.query(url, param);
    }

    getAdminNotificationsCount(adminUserId) {
        let url = `${this.#api}/${adminUserId}/count?is_not_viewed=0`;
        return apiService.get(url);
    }

    readNotification(notificationId){
        let url = `${this.#api}/${notificationId}/read`;
        window.open(url);
    }

    markAsViewed(notificationId){
        let url = `${this.#api}/${notificationId}/mark-as-viewed`;
        return apiService.get(url);
    }

    markAsNotViewed(notificationId){
        let url = `${this.#api}/${notificationId}/mark-as-not-viewed`;
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}