<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} address</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined :error="$v.address.address_line_1.$error"
                  dense label="Address line 1"
                  v-model="address.address_line_1"
              >
                <template v-slot:label>
                  Address line 1 <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.address_line_1">{{errors.address_line_1[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.address_line_1.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense label="Address line 2"
                  v-model="address.address_line_2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete @change="toggleCountry()"
                              v-model="address.country_id"
                              :items="countries" filled
                              outlined
                              dense color="blue-grey lighten-2"
                              label="Country"
                              item-text="name"
                              item-value="id">

              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="toggle.stateList==false">
              <v-text-field label="State*" outlined dense
                            :error="$v.address.state.$error" v-model="address.state">
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.address.state.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="toggle.stateList">
              <v-autocomplete
                  v-model="address.state_id" :error="$v.address.state_id.$error"
                  :items="states" filled
                  outlined
                  dense color="blue-grey lighten-2"
                  label="Select States"
                  item-text="name"
                  item-value="id">
                <template v-slot:label>
                  State <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.address.state_id.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  outlined
                  dense label="Suburb" :error="$v.address.suburb.$error"
                  v-model="address.suburb"
              >
                <template v-slot:label>
                  Suburb <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.suburb">{{errors.suburb[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.suburb.$error">This information is required</span>
            </v-col>

            
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  outlined :error="$v.address.post_code.$error"
                  dense label="Post code"
                  v-model="address.post_code"
              >
                <template v-slot:label>
                  Post code <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.post_code">{{errors.post_code[0]}}<br></span>
              <span class="text-danger" v-if="$v.address.post_code.$error">This information is required</span>

            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="address.type == 'work'">
              <v-text-field
                  outlined 
                  dense 
                  label="Business name"
                  v-model="address.business_name"
              >
                <template v-slot:label>
                  Business name
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.business_name">{{errors.business_name[0]}}<br></span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <label><strong>Type <span class="text-danger">*</span></strong></label>
            <v-radio-group v-model="address.type" row>
              <v-radio
                  label="Work"
                  value="work"
                  color="indigo"
              ></v-radio>
              <v-radio
                  label="Home"
                  value="home"
                  color="red">
              </v-radio>
              <v-radio
                  label="Other"
                  value="other"
                  color="red">
              </v-radio>
            </v-radio-group>
            <span class="text-danger" v-if="$v.address.type.$error">This information is required</span>
            </v-col>


            <v-col cols="12" sm="12" md="4" v-if="!this.edit">
              <span class="font-weight-medium">Set as postal address</span>
              <v-switch
                  v-model="address_info.default_shipping_address"
                  :label="address_info.default_shipping_address ? 'Yes':'No'"
                  hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" md="4" v-if="!this.edit">
              <span class="font-weight-medium">Set as billing address</span>
              <v-switch
                  v-model="address_info.default_billing_address"
                  :label="address_info.default_billing_address ? 'Yes':'No'"
                  hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="font-weight-medium">Account status</span>
              <v-switch
                  v-model="address.is_active"
                  :label="address.is_active ?'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           x-large
           dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Submit
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/setting/state/StateService";
import CountryService from "@/services/country/CountryService";
import {required, requiredIf} from "vuelidate/lib/validators";
import UserSettingService from "@/services/user/UserSettingService";

const userSeting=new UserSettingService;
const country=new CountryService();
const state =new StateService;
const address=new UserAddressService;
export default {
  props: ['userId','user_type'],
  validations:{
    address:{
      address_line_1:{required},
      type:{required},
      suburb:{required},
      post_code:{required},
      state: {
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==false;
        })
      },
      state_id:{
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==true;
        })
      },
    },
  },
  data(){
    return{
      edit:false,
      title:'',
      dialog: false,
      loading: false,
      address:{
        address_line_1:'',
        address_line_2:'',
        suburb:'',
        state:'',
        state_id:'',
        post_code:'',
        country_id:'',
        type:'',
        business_name: '',
        is_active:1,
        user_type:'',
      },
      errors:[],
      countries:[],
      states:[],
      address_info:{
         default_billing_address_id:null,
        default_billing_address:false,
        default_shipping_address_id:null,
        default_shipping_address:false,
      },
      toggle:{
        stateList:true
      },
    }
  },
  mounted() {
    this.getAllState();
    this.getCountries();
  },
  methods: {
    toggleCountry(){

      if(this.address.country_id!=14){
          this.toggle.stateList=false;
      }else{
        this.toggle.stateList=true;
      }
    },
    closeDialog() {

      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset();

    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){

      this.address_info.default_billing_address_id='';
      this.address_info.default_billing_address=false;
      this.address_info.default_shipping_address_id='';
      this.address_info.default_shipping_address=false;
      this.address={
        address_line_1:'',
        address_line_2:'',
        suburb:'',
        state:'',
        state_id:'',
        post_code:'',
        country_id:14,
        type:'home',
        business_name: '',
        is_active:1,
      };
    },
    createUserAddress() {
      this.edit=false;
      this.resetForm();
      this.openDialog();
      this.title = "Add";
    },
    editAddress(item){
      this.edit=true;
      this.address=item;
      this.openDialog();
      this.toggleCountry();
      this.title = "Edit";
    },
    createOrUpdate() {
      this.address.user_type = this.user_type;
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading=true;
      this.address.user_id=this.userId;
      address
          .create(this.address)
          .then(response => {
            this.$snotify.success("Address added");
            this.address_info.default_billing_address_id=response.data.address.id;
            this.address_info.default_shipping_address_id=response.data.address.id;
            this.useAddress();
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
            this.loading=false;
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update(){
      this.loading=true;
      address
          .update(this.address.id, this.address)
          .then(response => {
            this.$snotify.success("Address updated");
            this.closeDialog();
            this.resetForm();
            this.loading=false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    useAddress(){
      let data={};
      if(this.address_info.default_shipping_address==true && this.address_info.default_billing_address ==true){
        data['default_shipping_address_id']=this.address_info.default_shipping_address_id;
        data['default_billing_address_id']=this.address_info.default_billing_address_id;
      }else if(this.address_info.default_billing_address ==true){
        data['default_billing_address_id']=this.address_info.default_billing_address_id;
      } else if(this.address_info.default_shipping_address==true){
        data['default_shipping_address_id']=this.address_info.default_shipping_address_id;
      }
      data['user_id']=this.userId;
      userSeting
          .create(data)
          .then(response => {
            // this.$emit("update-accountholder");
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          });
    },
    getAllState()
    {
      state
          .all()
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {

          });
    },
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {

          })
    },


  }
}
</script>