import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class OnlineMarkerService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/online-marker';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    resetPassword(id,data){
        let url = `${this.#api}/${id}/reset-password`;
        return apiService.post(url,data);
    }

    getAllLog(id){
        let url = `${this.#api}/${id}/get-logs`;
        return apiService.get(url);
    }

    // exports
    exportToCsv(data = {}){
        let url = `${this.#api}/export/csv`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }
    
    exportToExcel(data = {}){
        let url = `${this.#api}/export/excel`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }

    getExportFilters(){
        let url = `${this.#api}/get-search-parameters`;
        return apiService.get(url);
    }

    loginAsOnlineMarker(onlineMarkerId)
    {
        let url = `${this.#api}/login-as/${onlineMarkerId}/online-marker`
        return apiService.post(url)
    }
    search(data={}){
        let url = `${this.#api}/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    getCompleteSearchUrl(data={},index = null) {

        let url = this.#api;
        return (url);
    }
}