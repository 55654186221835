<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="d-flex flex-row" v-else>
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
         id="kt_profile_aside">
      <div class="card card-custom card-stretch" style="border-right: 1px solid #efefef;">
        <div class="card-body pt-4">

          <side-profile :user-detail="onlineMarkerDetail"></side-profile>

          <!--Tab-->
          <tab-list :user-detail="onlineMarkerDetail" @updateTabIndex="updateTabIndex"></tab-list>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <OnlineMarkerProfile
              :online_marker_profile ="onlineMarkerDetail"
              @update-online-marker="getOnlineMarkerDetail"
          ></OnlineMarkerProfile>
        </b-tab>

        <b-tab>
          <notes></notes>
        </b-tab>

        <b-tab>
          <PracticalCandidate></PracticalCandidate>
        </b-tab>

        <b-tab>
          <ExamCandidate></ExamCandidate>
        </b-tab>

        <b-tab>
          <CourseCandidate></CourseCandidate>
        </b-tab>

        <b-tab>
          <AmebAwardCandidate></AmebAwardCandidate>
        </b-tab>

        <b-tab>
          <transaction-summary></transaction-summary>
        </b-tab>

        <b-tab>
          <support-ticket></support-ticket>
        </b-tab>

        <b-tab>
          <email-activity></email-activity>
        </b-tab>

        <b-tab >
          <addresses :user_type="'accountholder'"></addresses>
        </b-tab>

        <b-tab>
          <user-role :user_role="onlineMarkerDetail" @update="getOnlineMarkerDetail()"></user-role>
        </b-tab>

        <b-tab>
          <OnlineMarkerSettings @update-onlineMarker="getOnlineMarkerDetail" :user_score="onlineMarkerDetail"></OnlineMarkerSettings>
        </b-tab>

        <b-tab>
          <security :user_security="onlineMarkerDetail" ></security>
        </b-tab>

        <b-tab>
          <information-log></information-log>
        </b-tab>

      </b-tabs>
    </div>
  </div>
  </v-app>
</template>

<script>
import OnlineMarkerProfile from "./widgets/Profile";
import OnlineMarkerSettings from "./widgets/Settings";
import UserRole from "@/view/pages/view/user/general/role/Role";
import Security from  "@/view/pages/view/user/general/security/Security";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";
import Notes from "@/view/pages/view/user/general/notes/Notes";
import EmailActivity from "@/view/pages/view/user/general/email-activity/EmailActivity";
import InformationLog from "@/view/pages/view/user/general/information-log/InformationLog";
import Addresses from "@/view/pages/view/user/general/address/Address";
import SideProfile from "@/view/pages/view/user/general/side-profile/SideProfile";
import TabList from "@/view/pages/view/user/general/tab-list/TabList";
import SupportTicket from "@/view/pages/view/user/accountholder/widgets/SupportTicket";
import TransactionSummary from "@/view/pages/view/user/general/transaction/TransactionSummary";
import ExamCandidate from "@/view/pages/view/user/general/enrolments/ExamCandidate";
import CourseCandidate from "@/view/pages/view/user/general/enrolments/CourseCandidate";
import PracticalCandidate from "@/view/pages/view/user/general/enrolments/PracticalCandidate";
import AmebAwardCandidate from "@/view/pages/view/user/general/enrolments/AmebAwardCandidate";

const onlineMarker=new OnlineMarkerService();
export default {
  name: "online_marker",
  components: {
    TransactionSummary,
    SupportTicket,
    TabList,
    SideProfile,
    Addresses,
    InformationLog,
    EmailActivity,
    Notes,
    OnlineMarkerProfile,
    OnlineMarkerSettings,
    UserRole,
    Security,
    ExamCandidate,
    CourseCandidate,
    PracticalCandidate,
    AmebAwardCandidate
  },
  data() {
    return {
      loading:true,
      attrs: [],
      tabIndex: 0,
      onlineMarkerDetail:{},
     online_marker_setting:{},
    };
  },
  mounted() {
    this.getOnlineMarkerDetail();
  },
  methods: {
    updateTabIndex(tabIndex) {
      this.tabIndex = tabIndex;
    },

    getOnlineMarkerDetail() {
      onlineMarker
          .show(this.onlineMarkerId)
          .then(response => {
            this.onlineMarkerDetail = response.data.onlineMaker;
          }).finally(() => {
          this.loading = false
      })
    }
  },
  computed:{
    onlineMarkerId() {
      return this.$route.params.onlineMarkerId;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    routeTab(){
      if(this.onlineMarkerDetail){
        let title = this.onlineMarkerDetail.full_name + '- Online Marker'
        return {
          title: title
        }
      }
    }
  },


};
</script>
