<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span >Change SCORE</span>
        </v-card-title>
        <v-card-text>

          <v-container>
            <v-row>
              <v-col cols="12" v-if="is_examiner && !is_score">
                <v-select
                    label="Examining Score"
                    item-text="name"
                    item-value="id"
                    :items="scores"
                    v-model="user_score.examining_scores"
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                    outlined
                    dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="allSelectScores"
                    >
                      <v-list-item-action>
                        <v-icon :color="user_score.examining_scores > 0 ? 'indigo darken-4' : ''">
                          {{ iconScore }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col
                  cols="12"
                  v-if="!is_examiner && is_score"
              >
                <v-select
                    outlined
                    dense item-text="name"
                    item-value="id"
                    :items="scores"
                    v-model="user_score.current_score_id"
                >
                  <template v-slot:label>
                    Score <span class="text-danger">*</span>
                  </template>
                </v-select>
                <span class="text-danger" v-if="$v.user_score.current_score_id.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
             text
             x-large
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
             dark
             x-large
              :loading="loading"
              @click="createOrUpdate"
          >
            Save
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import ScoreService from "@/services/score/score/ScoreService";
import {required} from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";

const score = new ScoreService();
const accountHolder=new AccountholderService();
const examiner = new ExaminerService();
const supervisor = new SupervisorService();
const onlineMarker = new OnlineMarkerService();

export default {
  name: "ChangeScore",
  data() {
    return {
      dialog: false,
      loading: false,
      is_examiner: false,
      is_score: false,
      user_score:{
        current_score_id:'',
        examining_scores: [],
      },
      scores: [],
      examining_scores: [],
    }
  },
  validations:{
    user_score: {
      current_score_id: { required }
    }
  },
  mounted() {
    this.getAllScore();
  },
  computed: {
    allSelectScore () {
      return this.user_score.examining_scores.length === this.scores.length
    },
    selectScore () {
      return this.user_score.examining_scores.length > 0 && !this.allSelectScore
    },
    iconScore () {
      if (this.allSelectScore) return 'mdi-close-box'
      if (this.selectScore) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    openDialog(user_score, is_score) {
      this.dialog = true;
      this.user_score = user_score;
      if(this.user_score.examining_scores == null) {
        this.user_score.examining_scores = [];
      }
      if(is_score == 'score') {
        this.is_score = true;
        this.is_examiner = false;
      }
      if(is_score == 'examining_scores') {
        this.is_score = false;
        this.is_examiner = true;
      }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            //
          });
    },
    allSelectScores () {
      this.$nextTick(() => {
        if (this.allSelectScore) {
          this.user_score.examining_scores = []
        } else {
          this.user_score.examining_scores = [];
          this.scores.forEach(score => {
            this.user_score.examining_scores.push(score.id);
          });
        }
      })
    },
    updateAccountHolderScore() {
      accountHolder
          .update(this.user_score.id, this.user_score)
          .then(response => {
            this.$emit("update");
            this.$snotify.success("Score updated");
            this.resetForm();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    updateExaminerScore() {
      examiner
          .update(this.user_score.id, this.user_score)
          .then(response => {
            this.$emit("update");
            this.$snotify.success("Score updated");
            this.resetForm();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    updateSupervisorScore() {
      supervisor
          .update(this.user_score.id, this.user_score)
          .then(response => {
            this.$emit("update");
            this.$snotify.success("Score updated");
            this.resetForm();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    updateOnlineMarkerScore() {
      onlineMarker
          .update(this.user_score.id, this.user_score)
          .then(response => {
            this.$emit("update");
            this.$snotify.success("Score updated");
            this.resetForm();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true;
        if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
          this.updateAccountHolderScore();
        } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
          this.updateExaminerScore();
        } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
          this.updateSupervisorScore();
        } else {
          this.updateOnlineMarkerScore();
        }
      }
    },
    resetForm() {
      this.$v.$reset();
      this.dialog = false;
      this.user_score = {
        current_score_id:'',
        examining_scores: [],
      }
    }
  }
}
</script>

<style scoped>

</style>