<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
         User roles
        </h3>
<!--        <span class="text-muted font-weight-bold font-size-sm mt-1">Update Accountholder Roles</span>-->
      </div>
      <div class="card-toolbar">
        <v-btn x-large dark
                @click="updateRole()">
          Save
        </v-btn>
      </div>
    </div>
    <form class="form">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <h2 class="col-12 col-form-label">Summary of Roles this user is set up to access:</h2>
          <p>After making changes to these roles, don’t forget to click Save.</p>
        </div>
        <div class="form-group row align-items-center">
          <div class="col-12" style="padding: 5px;">
            <div class="checkbox-inline">
              <label class="checkbox mr-2 ml-1">
                <input type="checkbox" v-model="user_role.is_account_holder" :disabled="userType == 'enroler'" />
                <span :style="userType == 'enroler' ? {'background-color': '#3699ffad'}: ''"></span>&nbsp;<label class="col-form-label">Enroller</label>
              </label>

            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <div class="col-12" style="padding: 5px;">
            <div class="checkbox-inline">
              <label class="checkbox mr-2 ml-1">
                <input type="checkbox" v-model="user_role.is_examiner" :disabled="userType == 'examiner'" />
                <span :style="userType == 'examiner' ? {'background-color': '#3699ffad'}: ''"></span>&nbsp;<label class="col-form-label">Examiner</label>
              </label>

            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <div class="col-12" style="padding: 5px;">
            <div class="checkbox-inline">
              <label class="checkbox mr-2 ml-1">
                <input type="checkbox"  v-model="user_role.is_supervisor" :disabled="userType == 'supervisor'" />
                <span :style="userType == 'supervisor' ? {'background-color': '#3699ffad'}: ''"></span>&nbsp;<label class="col-form-label">Supervisor</label>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <div class="col-12" style="padding: 5px;">
            <div class="checkbox-inline">
              <label class="checkbox mr-2 ml-1">
                <input type="checkbox" v-model="user_role.is_online_marker" :disabled="userType == 'onlinemarker'" />
                <span :style="userType == 'onlinemarker' ? {'background-color': '#3699ffad'}: ''"></span>&nbsp;<label class="col-form-label">Online marker</label></label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <v-overlay :value="role_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";

const accountholder = new AccountholderService();
const examiner = new ExaminerService();
const supervisor = new SupervisorService();
const onlineMarker = new OnlineMarkerService();
export default {
  props: ['user_role'],
  name: "UserRole",
  data() {
    return{
      role_overlay:false,
      userId: "",
      userType: "",
    }
  },
  mounted() {
    this.setUserIdAndType();
  },
  methods:{
    setUserIdAndType() {
      if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
        this.userType = "enroler";
        this.userId = this.$route.params.accountholderId;
      } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
        this.userType = "examiner";
        this.userId = this.$route.params.examinerId;
      } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
        this.userType = "supervisor";
        this.userId = this.$route.params.supervisorId;
      } else {
        this.userType = "onlinemarker";
        this.userId = this.$route.params.onlineMarkerId;
      }
    },
    updateRole(){
      if (this.$route.params.accountholderId != undefined && this.$route.params.accountholderId != '') {
        this.updateAccountHolderRole();
      } else if (this.$route.params.examinerId != undefined && this.$route.params.examinerId != '') {
        this.updateExaminerRole();
      } else if (this.$route.params.supervisorId != undefined && this.$route.params.supervisorId != '') {
        this.updateSupervisorRole();
      } else {
        this.updateOnlineMarkerRole();
      }
    },
    updateAccountHolderRole(){
      this.role_overlay=true;
      accountholder
          .update(this.userId, this.user_role)
          .then(response => {
            this.$snotify.success("Role updated");
            this.$emit("update");
            this.close();
          })
          .catch((err) => {
            this.errors = err.errors;
          })
          .finally(() => {
            this.role_overlay = false;
        });
    },
    updateExaminerRole(){
      this.role_overlay=true;
      examiner
          .update(this.userId, this.user_role)
          .then(response => {
            this.$snotify.success("Role updated");
            this.$emit("update");
            this.close();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.role_overlay = false;
      });
    },
    updateSupervisorRole(){
      this.role_overlay=true;
      supervisor
          .update(this.userId, this.user_role)
          .then(response => {
            this.$snotify.success("Role updated");
            this.$emit("update");
            this.close();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.role_overlay = false;
      });
    },
    updateOnlineMarkerRole(){
      this.role_overlay=true;
      onlineMarker
          .update(this.userId, this.user_role)
          .then(response => {
            this.$snotify.success("Role updated");
            this.$emit("update");
            this.close();
          })
          .catch((err) => {
            this.errors = err.errors;
          }).finally(() => {
        this.role_overlay = false;
      });
    },

  }
}
</script>